import React from "react";
import PropTypes from "prop-types";
import PageContext from "./PageContext";

import { getDisplayName } from "../../utils";

const withPageContext = ( Component : React.FC ) => {

    const WrapperComponent = ( props : any ) => {

      return (
        <PageContext.Provider value={props.pageContext}>
          <Component {...props} />
        </PageContext.Provider>
      );
    };


    WrapperComponent.displayName = `PageContext(${getDisplayName(Component)})`;

    return WrapperComponent;
};

withPageContext.propTypes = {
    children: PropTypes.node.isRequired
};

export default withPageContext;
