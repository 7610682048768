import React , { ReactNode } from "react";
import withPageContext from "../context/pageContext";
import { useLocalization } from "gatsby-theme-i18n";
import { GlobalStyles } from 'twin.macro'

import { withPrefix } from 'gatsby';


const alertJson = require('../../locales/alert.json');

type Props = {
  children?: ReactNode;
};


const MainTemplate: React.FC<Props> = (props) => {

  const { children } = props;

  const { currentLocaleObj } = useLocalization();

  return (
    <>
      <noscript>
        <div dangerouslySetInnerHTML={{__html: `<div id="alert" class="mask" style="position:fixed;top:0;left:0;bottom:0;right:0;background:#F7F7FA;line-height:100vh;z-index:9999;text-align:center;"><div class="alert_box" style="display:inline-block;position:relative;width:928px;max-width:100%;box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.05), 0px 6px 6px -6px rgba(0, 0, 0, 0.03), 0px 16px 14px -14px rgba(0, 0, 0, 0.03);border-radius: 6px;background: #FCFCFF;padding:108px 48px 96px;color:#444448;font-size: 20px;line-height: 32px;text-align:center;margin:100px auto;vertical-align:middle;"><img style="width:104px;height:104px;display:block;margin:0 auto 44px;"src="${withPrefix('notice.jpg')}" />${alertJson[currentLocaleObj.code].javascript}</div></div>`}}/>
      </noscript>

      <GlobalStyles />
      {children}
    </>
  );

}

export default withPageContext(MainTemplate);
